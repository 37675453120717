import {mapGetters, mapMutations} from "vuex";
//sections
import sizesTable from '@/components/sizes/index.vue';
export default {
  name: "sizes",
  components:{
    sizesTable
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      contacts: 'pages/contact'
    })
  },

  methods:{
    ...mapMutations({
    })
  }
}